import React, { Component } from 'react';
import '../components/form.css';

class Form extends Component {
  constructor() {
    super();
    this.state = {
      // isLoggedIn: false,
      name: '',
      email: '',
      message: '',
      nameError: false,
      emailError: false,
      messageError: false,
    };
  }

  handleNameChange = (e) => {
    this.setState({ name: e.target.value });
  };

  handleEmailChange = (e) => {
    this.setState({ email: e.target.value });
  };
  handleMessageChange = (e) => {
    this.setState({ message: e.target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { name, email, message } = this.state;
    if (name && email && message) {
      this.setState({ isLoggedIn: true });
    }
    if (!name) {
      this.setState({ nameError: true });
    }
    if (!email) {
      this.setState({ emailError: true });
    }
    if (!message) {
      this.setState({ messageError: true });
    }
  };

  render() {
    return (
      <form
        onSubmit={this.handleSubmit}
        name="contact"
        method="POST"
        data-netlify="true"
      >
        <div className="form__group field">
          <input
            value={this.state.name}
            onChange={this.handleNameChange}
            type="input"
            class="form__field"
            placeholder="Name"
            name="name"
            id="name"
            required
          />
          <label htmlFor="name" className="form__label">
            Name
          </label>
        </div>

        <input type="submit" value="Submit" />
      </form>
    );
  }
}

export default Form;
